<template>
  <div class="flex-row justify-between section_1">
    <div class="flex-row space-x-12 group click-cursor" @click="homeBtn()">
      <img :src="websiteInfo.pc_logo" class="image" />
      <span class="text">中和职业培训中心</span>
    </div>
    <div class="flex-row space-x-15 group_1">
      <div class="flex-row space-x-5 group_2 click-cursor" @click="userInfoBtn()" v-if="identity == 0">
        <img :src="userInfo.headimg" class="image_1 " />
        <span class="text_1">{{userInfo.name}}</span>
      </div>
      <div class="flex-row space-x-5 group_2 click-cursor" @click="userInfoBtn()" v-if="identity == 1">
        <img :src="copyInfo.logo" class="image_1 " />
        <span class="text_1">{{copyInfo.enterprise_name}}</span>
      </div>
      <div class="section_2">
        <!---->
      </div>
      <img src="../../../public/static/msg.png" class="image_2 image_3 click-cursor" @click="msgBtn()" />
      <img src="../../../public/static/what.png" class="image_2 image_4 click-cursor" @click="whatBtn()" />
      <img src="../../../public/static/close.png" class="image_2 image_5" @click="logout()" />
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        userInfo: [],
        copyInfo:[],
		websiteInfo: [],
      };
    },
    beforeCreate() {

    },
    created() {
      let that = this;
      that.getUser();
	  that.getWebsiteInfo();
    },
    methods: {
		// 配置信息
		getWebsiteInfo() {
		  let that = this;
		  that.$request.getWebsiteInfo().then((res) => {
			if (res.code == 200) {
			  that.websiteInfo = res.datas;
			}
		  });
		},
      msgBtn() {
        let that = this;
        that.$router.push({
          name: 'userMessageuser'
        });
      },
      userInfoBtn() {
        let that = this;
        that.$router.push({
          name: 'userIndexInfo'
        });
        query: {
          selectId: 1
        }
      },
      getUser() {
        let that = this;
        that.identity = sessionStorage.getItem('identity');
        that.$request.getUserInfo().then((res) => {
          if (res.code == 200) {
            if(that.identity == 0){
              that.userInfo = res.datas;
               return false;
            }
            if(that.identity == 1){
              that.copyInfo = res.datas;
               return false;
            }
          } else if (res.code == 10001) {
            //服务器登录过期了，清除本地token和更改登录状态
            window.sessionStorage.clear()
            that.$router.replace('/login');
          }
        });

      },
      // 问题提示
      whatBtn() {
        let that = this;
        that.$router.push({
          name: 'userWhat'
        });
      },
      // 跳转首页
      homeBtn() {
        let that = this;
        that.$router.push({
          name: 'userAccountinfo'
        });

      },

      //退出登录
      logout() {
        let that = this;
        // 这里需要elementui的支持，如果使用其他界面组件自行替换即可
        that.$confirm('是否退出登录？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //清除登录状态缓存
          window.sessionStorage.clear()
          that.$store.commit('changeLoginState', 0);
          //element-ui提示
          that.$myMessage.success('退出登录');
          that.$router.replace('/login')
        }).catch(() => {
          // 如果取消跳转地址栏会变化，这时保持地址栏不变
          window.history.go(1)
        })

      },
    }
  }
</script>

<style scoped>
  .click-cursor {
    cursor: pointer;
  }

  .section_1 {
    padding: 0.63rem 1.25rem 0.63rem 3.19rem;
    background-color: #ffffff;
  }

  .space-x-12>*:not(:first-child) {
    margin-left: 0.75rem;
  }

  .group {
    margin: 0.13rem 0;
    overflow: hidden;
    width: 15.56rem;
    height: 2.88rem;
  }

  .image {
    flex-shrink: 0;
    width: 2.88rem;
    height: 2.88rem;
  }

  .text {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 1.5rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.41rem;
  }

  .space-x-15>*:not(:first-child) {
    margin-left: 0.94rem;
  }

  .group_1 {
    overflow: hidden;
    width: 12.88rem;
    height: 3.13rem;
  }

  .space-x-5>*:not(:first-child) {
    margin-left: 0.31rem;
  }

  .group_2 {
    overflow: hidden;
    width: 5.63rem;
    height: 3.13rem;
  }

  .image_1 {
    flex-shrink: 0;
    width: 2.5rem;
    height: 3.13rem;
  }

  .text_1 {
    align-self: center;
    color: #3d3d3d;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.88rem;
  }

  .section_2 {
    align-self: center;
    background-color: #d8d8d8;
    width: 0.094rem;
    height: 0.81rem;
  }

  .image_2 {
    width: 1.13rem;
    height: 1.13rem;
  }

  .image_3 {
    align-self: center;
  }

  .image_4 {
    align-self: center;
  }

  .image_5 {
    align-self: center;
    cursor: pointer;
  }

  .space-y-33>*:not(:first-child) {
    margin-top: 2.06rem;
  }

  .group_5 {
    padding: 1.38rem 1.19rem 0;
  }

  .text_3 {
    margin: 0.094rem 0 0.094rem 1.53rem;
    color: #74798c;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.91rem;
  }

  .group_6 {
    padding: 0 1.13rem;
  }

  .text_4 {
    margin: 0.16rem 0 0.16rem 1.5rem;
    color: #74798c;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .group_7 {
    padding-left: 1.13rem;
    padding-right: 0.81rem;
  }

  .image_10 {
    flex-shrink: 0;
  }

  .text_5 {
    margin: 0.16rem 0 0.16rem 1.47rem;
    color: #74798c;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .image_11 {
    margin: 0.31rem 0 0.31rem 2.84rem;
    width: 0.63rem;
    height: 0.63rem;
  }

  .space-x-20>*:not(:first-child) {
    margin-left: 1.25rem;
  }

  .view_1 {
    margin-top: 2.13rem;
  }

  .section_5 {
    margin: 0.25rem 0 0.19rem;
    border-radius: 50%;
    width: 0.38rem;
    height: 0.38rem;
    border: solid 0.094rem #666666;
  }

  .text_6 {
    color: #74798c;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .view_3 {
    margin-top: 2.31rem;
  }

  .text_7 {
    color: #74798c;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .view_5 {
    margin-top: 2.31rem;
  }

  .text_8 {
    color: #74798c;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .space-x-25>*:not(:first-child) {
    margin-left: 1.56rem;
  }

  .group_10 {
    margin-top: 2.25rem;
  }

  .image_12 {
    width: 1.13rem;
    height: 1.06rem;
  }

  .text_9 {
    margin-top: 0.094rem;
    color: #74798c;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.91rem;
  }

  .group_11 {
    margin-top: 2.13rem;
  }

  .text_10 {
    margin: 0.16rem 0 0.16rem 1.5rem;
    color: #74798c;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .group_12 {
    margin-top: 2rem;
  }

  .text_11 {
    margin: 0.16rem 0 0.16rem 1.47rem;
    color: #74798c;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .space-x-23>*:not(:first-child) {
    margin-left: 1.44rem;
  }

  .group_13 {
    margin-top: 2.06rem;
    padding: 0 0.13rem;
  }

  .image_15 {
    width: 1.19rem;
    height: 1.19rem;
  }

  .text_12 {
    margin-bottom: 0.16rem;
    color: #74798c;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .group_14 {
    margin-top: 2rem;
  }

  .text_13 {
    margin: 0.16rem 0 0.16rem 1.41rem;
    color: #74798c;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .space-y-20>*:not(:first-child) {
    margin-top: 1.25rem;
  }
</style>
