<template>
  <div class="flex-col page">
    <top></top>
    <div class="flex-row">
      <!-- 菜单栏 -->
      <leftMenus></leftMenus>
      <right></right>
    </div>
  </div>
</template>

<script>
  import top from './head.vue';
  import leftMenus from './leftMenus.vue';
  import right from './right.vue';
  export default {
    components: {
      leftMenus,
      right,
      top
    },
    data() {
      return {};
    },
    methods: {},
  };
</script>

<style scoped lang="css">
  .page {
    background-color: #f5f6fa;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }


</style>
