<template>
  <el-menu router :collapse="isCollapse" class="el-menu-vertical-demo" :router="true" :default-active="activerouter"
    @open="handleOpen()" @close="handleClose()">

    <el-menu-item index="/user/accountinfo">
      <i class="el-icon-menu"></i>
      <span slot="title">账户概览</span>
    </el-menu-item>
    <el-menu-item index="/user/curriculum/index">
      <i class="el-icon-postcard"></i>
      <span slot="title">课程中心</span>
    </el-menu-item>
	<el-menu-item index="/company/curriculum/course" v-if="identity == 1">
	  <i class="el-icon-shopping-bag-1"></i>
	  <span slot="title">消费记录</span>
	</el-menu-item>
	<!-- <el-menu-item index="/user/exam/list" v-if="identity == 1">
	  <i class="el-icon-shopping-bag-1"></i>
	  <span slot="title">补考管理</span>
	</el-menu-item> -->
	<!-- <el-menu-item index="/company/staff/index" v-if="identity == 1">
	  <i class="el-icon-shopping-bag-1"></i>
	  <span slot="title">员工管理</span>
	</el-menu-item> -->
    <el-menu-item index="/user/order/index" v-if="identity == 0">
      <i class="el-icon-shopping-bag-1"></i>
      <span slot="title">订单管理</span>
    </el-menu-item>
    <el-menu-item index="/user/exam/list" v-if="identity == 0">
      <i class="el-icon-shopping-bag-1"></i>
      <span slot="title">我的补考</span>
    </el-menu-item>
    <el-menu-item index="/user/training/list" v-if="identity == 0">
      <i class="el-icon-shopping-bag-1"></i>
      <span slot="title">全部课程</span>
    </el-menu-item>
	<el-menu-item index="/user/index/index"  v-if="identity == 1">
		<i class="el-icon-shopping-bag-1"></i>
		<span slot="title">企业信息</span>
	</el-menu-item>
		
<!--    <el-menu-item index="/user/accountinfo">
      <i class="el-icon-menu"></i>
      <span slot="title">账户概览</span>
    </el-menu-item>
    <el-menu-item index="/user/curriculum/index">
      <i class="el-icon-postcard"></i>
      <span slot="title">课程中心</span>
    </el-menu-item>
    <el-menu-item index="/user/order/index">
      <i class="el-icon-shopping-bag-1"></i>
      <span slot="title">订单管理</span>
    </el-menu-item>
    <el-menu-item index="/user/exam/list">
      <i class="el-icon-shopping-bag-1"></i>
      <span slot="title">我的补考</span>
    </el-menu-item>
    <el-menu-item index="/user/training/list">
      <i class="el-icon-shopping-bag-1"></i>
      <span slot="title">全部课程</span>
    </el-menu-item> -->
    <!-- <el-submenu index="4">
          <template slot="title">
            <i class="el-icon-bank-card"></i>
            <span>培训管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="4-1">全部课程</el-menu-item>
            <el-menu-item index="4-2">面授课程表</el-menu-item>
            <el-menu-item index="4-2">我的补考</el-menu-item>
          </el-menu-item-group>
        </el-submenu> -->
     <el-menu-item index="/user/training/records" v-if="identity == 0">
          <i class="el-icon-shopping-bag-1"></i>
          <span slot="title">培训记录</span>
        </el-menu-item>
    <el-menu-item index="/user/index/info" v-if="identity == 0">
          <i class="el-icon-shopping-bag-1"></i>
          <span slot="title">学员信息</span>
        </el-menu-item>
    <el-menu-item index="/user/invoice/details">
      <i class="el-icon-shopping-bag-1"></i>
      <span slot="title">发票管理</span>
    </el-menu-item>
	
		<el-menu-item index="/company/qualifications/index" v-if="identity == 1">
		  <i class="el-icon-shopping-bag-1"></i>
		  <span slot="title">企业资质管理</span>
		</el-menu-item>
		<el-menu-item index="/company/safetycer/index" v-if="identity == 1">
		  <i class="el-icon-shopping-bag-1"></i>
		  <span slot="title">企业安证管理</span>
		</el-menu-item>
		<el-menu-item index="/company/qiyeperson/index" v-if="identity == 1">
		  <i class="el-icon-shopping-bag-1"></i>
		  <span slot="title">员工证书管理</span>
		</el-menu-item>
    <!--        <el-menu-item index="8">
          <i class="el-icon-shopping-bag-1"></i>
          <span slot="title">求职信息</span>
        </el-menu-item> -->
		<el-menu-item index="/customer/index" v-if="identity == 1">
		  <i class="el-icon-shopping-bag-1"></i>
		  <span slot="title">联系客服</span>
		</el-menu-item>
    <el-menu-item @click="logout()">
      <i class="el-icon-shopping-bag-1"></i>
      <span slot="title">退出系统</span>
    </el-menu-item>
  </el-menu>
</template>

<script>
  export default {
    data() {
      return {
        activerouter: '',
        isCollapse: false,
        identity:sessionStorage.getItem('identity')
      }
    },
    /* mounted() {
        //获取地址栏中的路由，设置elementui中的导航栏选中状态
        this.activerouter = window.location.pathname;
     }, */
    created() {
      //获取路由中的路由元信息，设置elementui中的导航栏选中状态
      this.activerouter = this.$route.meta.activeMenu;
    },
    methods: {
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },
      //左侧栏目选中状态
      navLeftActive() {
        console.log(this.$route)
        const {
          meta,
          path
        } = this.$route
        if (meta.activeMenu) {
          return meta.activeMenu
        }
        return path
      },
      // 退出
      logout() {
        let that = this;
        // 这里需要elementui的支持，如果使用其他界面组件自行替换即可
        that.$confirm('是否退出登录？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //清除登录状态缓存
          window.sessionStorage.clear()
          that.$store.commit('changeLoginState', 0);
          //element-ui提示
          that.$myMessage.success('退出登录');
          that.$router.replace('/login')
        }).catch(() => {
          // 如果取消跳转地址栏会变化，这时保持地址栏不变
          window.history.go(1)
        })
      }
    }
  }
</script>

<style scoped>
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 900px;
  }

  .image_2 {
    width: 1.13rem;
    height: 1.13rem;
  }

  .image_3 {
    align-self: center;
  }

  .image_4 {
    align-self: center;
  }

  .image_5 {
    align-self: center;
  }

  .image_6 {
    width: 1.25rem;
    height: 1.25rem;
  }

  .space-y-33>*:not(:first-child) {
    margin-top: 2.06rem;
  }

  .section_3 {
    padding: 2.06rem 1.88rem 37.38rem;
    flex-shrink: 0;
    background-color: #ffffff;
    height: 77.25rem;
  }

  .group_5 {
    padding: 1.38rem 1.19rem 0;
  }

  .text_3 {
    margin: 0.094rem 0 0.094rem 1.53rem;
    color: #74798c;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.91rem;
  }

  .group_6 {
    padding: 0 1.13rem;
  }

  .text_4 {
    margin: 0.16rem 0 0.16rem 1.5rem;
    color: #74798c;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .group_7 {
    padding-left: 1.13rem;
    padding-right: 0.81rem;
  }

  .image_10 {
    flex-shrink: 0;
  }

  .text_5 {
    margin: 0.16rem 0 0.16rem 1.47rem;
    color: #74798c;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .image_11 {
    margin: 0.31rem 0 0.31rem 2.84rem;
    width: 0.63rem;
    height: 0.63rem;
  }

  .space-x-20>*:not(:first-child) {
    margin-left: 1.25rem;
  }

  .view_1 {
    margin-top: 2.13rem;
  }

  .left_nav_active {
    padding: 0.94rem 1.13rem;
    background-color: #0074ff;
    border-radius: 0.5rem;
  }

  .section_5 {
    margin: 0.25rem 0 0.19rem;
    border-radius: 50%;
    width: 0.38rem;
    height: 0.38rem;
    border: solid 0.094rem #666666;
  }

  .text_6 {
    color: #74798c;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .view_3 {
    margin-top: 2.31rem;
  }

  .text_7 {
    color: #74798c;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .view_5 {
    margin-top: 2.31rem;
  }

  .text_8 {
    color: #74798c;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }

  .space-x-25>*:not(:first-child) {
    margin-left: 1.56rem;
  }

  .group_10 {
    margin-top: 2.25rem;
  }

  .image_12 {
    width: 1.13rem;
    height: 1.06rem;
  }

  .text_9 {
    margin-top: 0.094rem;
    color: #74798c;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.91rem;
  }

  .group_11 {
    margin-top: 2.13rem;
  }

  .text_2 {
    margin: 0.16rem 3.16rem 0.16rem 1.47rem;
    color: #ffffff;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .text_10 {
    margin: 0.16rem 0 0.16rem 1.5rem;
    color: #74798c;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .group_12 {
    margin-top: 2rem;
  }

  .text_11 {
    margin: 0.16rem 0 0.16rem 1.47rem;
    color: #74798c;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .space-x-23>*:not(:first-child) {
    margin-left: 1.44rem;
  }

  .group_13 {
    margin-top: 2.06rem;
    padding: 0 0.13rem;
  }

  .image_15 {
    width: 1.19rem;
    height: 1.19rem;
  }

  .text_12 {
    margin-bottom: 0.16rem;
    color: #74798c;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }

  .group_14 {
    margin-top: 2rem;
  }

  .text_13 {
    margin: 0.16rem 0 0.16rem 1.41rem;
    color: #74798c;
    font-size: 1rem;
    font-family: Source Sans Pro;
    line-height: 0.94rem;
  }
</style>
